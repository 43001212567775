import React from "react";
import PropTypes from "prop-types";
import MetricCard from "../common/MetricCard";

const Dashboard = ({ currentQuarter }) => {
  return (
    <div className="dashboard main-content__body">
      <div className="cards-container cards-container--row">
        <MetricCard title={`Scrubbed Amount (${currentQuarter})`} />
        <MetricCard title={`Highest Performing Scrub (${currentQuarter})`} />
        <MetricCard title={`Total Invoice Amount (${currentQuarter})`} />
      </div>
      <div className="card">
        <div className="card__title">Recent Invoices</div>
      </div>
      <div className="cards-container cards-container--row">
        <div className="card">
          <div className="card__title">Scrubbed Amounts</div>
        </div>
        <div className="card">
          <div className="card__title">Scrub Rates of Largest Customers</div>
        </div>
      </div>
      <div className="cards-container cards-container--row">
        <div className="card">
          <div className="card__title">Scrub Type Breakdown ({currentQuarter})</div>
        </div>
        <div className="card">
          <div className="card__title">Customer Performance (Last 12 Months)</div>
        </div>
      </div>
      <div className="card">
        <div className="card__title">Product Distribution ({currentQuarter})</div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  currentQuarter: PropTypes.string.isRequired,
};

export default Dashboard;
