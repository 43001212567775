import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const TableLoadingSkeleton = ({ columns = 5, rows = 10 }) => {
  return [...Array(rows)].map((_, i) => (
    <tr className="hoverless-tr" key={i}>
      {[...Array(columns)].map((_, i) => (
        <td key={i}>
          <Skeleton animation="wave" />
        </td>
      ))}
    </tr>
  ));
};

TableLoadingSkeleton.propTypes = {
  columns: PropTypes.number,
  rows: PropTypes.number,
};

export default TableLoadingSkeleton;
