import React, { useState } from "react";
import PropTypes from "prop-types";
import MultiSelect from "../common/MultiSelect";
import ClaimCountAndMatchRate from "./ClaimCountAndMatchRate";
import MatchRatesByProduct from "./MatchRatesByProduct";

const filterTypes = {
  COVERED_ENTITIES: "coveredEntities",
  PRODUCTS: "products",
  MATCH_RATE: "matchRate",
};

const initialFilters = {
  coveredEntities: [],
  products: [],
};

const KeyTrends = ({ coveredEntities = [], products = [] }) => {
  const [filters, setFilters] = useState(initialFilters);

  const handleFilterChange = (filter, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: value,
    }));
  };

  const hasFiltersApplied = Object.values(filters).some((filter) => filter.length > 0);

  const clearFilters = () => setFilters(initialFilters);

  return (
    <div className="card">
      <div className="card__title">Key Trends</div>
      <div className="action-bar">
        <MultiSelect
          label="Covered Entity"
          options={coveredEntities}
          selectedOptions={filters.coveredEntities}
          onChange={(selected) => handleFilterChange(filterTypes.COVERED_ENTITIES, selected)}
          onClear={() => handleFilterChange(filterTypes.COVERED_ENTITIES, [])}
          showValueInLabel
        />
        <MultiSelect
          label="Product"
          options={products}
          selectedOptions={filters.products}
          onChange={(selected) => handleFilterChange(filterTypes.PRODUCTS, selected)}
          onClear={() => handleFilterChange(filterTypes.PRODUCTS, [])}
        />
        {hasFiltersApplied && (
          <button className="btn btn--plain" onClick={clearFilters}>
            Clear All
          </button>
        )}
      </div>
      <div className="cards-container cards-container--row">
        <ClaimCountAndMatchRate filters={filters} />
        <MatchRatesByProduct filters={filters} />
      </div>
    </div>
  );
};

KeyTrends.propTypes = {
  coveredEntities: PropTypes.array,
  products: PropTypes.array,
};

export default KeyTrends;
