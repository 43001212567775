import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Label } from "@radix-ui/react-label";
import SelectSearch from "../../common/SelectSearch";
import TextInput from "../../common/inputs/TextInput";
import DateInput from "../../common/inputs/DateInput";
import FileUpload from "../../common/FileUpload";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isEmptyValue } from "../../utils/validationUtils";

dayjs.extend(customParseFormat);

const NewInvoiceDrawer = ({
  customers,
  getLatestInvoiceData,
  hasAssignedMapping,
  onSubmit,
  showModal,
  setBasicInfo,
  basicInfo,
  handleFileChange,
}) => {
  const [fieldValidity, setFieldValidity] = useState({
    customerId: undefined,
    number: undefined,
    date: undefined,
    dueDate: undefined,
  });

  const customerList = customers.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const handleValidationChange = (name, isValid) => {
    setFieldValidity((prevFieldValidity) => ({
      ...prevFieldValidity,
      [name]: isValid,
    }));
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    setBasicInfo({
      ...basicInfo,
      number: value,
    });

    const isValid = !isEmptyValue(value);
    handleValidationChange("number", isValid);
  };

  const handleCustomerChange = ({ value }) => {
    setBasicInfo({
      ...basicInfo,
      customerId: value,
    });

    const isValid = !isEmptyValue(value);
    handleValidationChange("customerId", isValid);

    value && getLatestInvoiceData(value);
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value?.replace(/\s+/g, "");

    setBasicInfo({
      ...basicInfo,
      [name]: trimmedValue,
    });

    const isValid = dayjs(trimmedValue, "MM/DD/YYYY", true).isValid();

    handleValidationChange(name, isValid);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    onSubmit(basicInfo);
  };

  const { customerId, number, date, dueDate, stored_file } = basicInfo;

  const fileUploadIsDisabled = Object.values(fieldValidity).some((field) => !field);

  const submitIsDisabled = fileUploadIsDisabled || !stored_file || !hasAssignedMapping;

  const handleCancel = () => {
    const displayDiscardWarning = [customerId, number, date, dueDate, stored_file].some(
      (field) => field,
    );

    if (displayDiscardWarning) return showModal("discard");

    return Turbo.visit("/invoices");
  };

  return (
    <div className="form">
      <div className="form__heading">Basic Information</div>
      <form className="form__content">
        <div className="form__content__item">
          <Label className="form__content__label" htmlFor="customer">
            Customer
          </Label>
          <SelectSearch
            inputId="customer"
            testId="customer-select"
            options={customerList}
            emptyOptionLabel="Choose Customer"
            placeholder="Choose Customer"
            onChange={handleCustomerChange}
            defaultValue={{ value: "", label: "Choose Customer" }}
            value={{
              value: customerId,
              label:
                customerList.find((customer) => customer.value === customerId)?.label ||
                "Choose Customer",
            }}
          />
        </div>
        <div className="form__content__item">
          <label className="form__content__label" htmlFor="number">
            Invoice Number
          </label>
          <TextInput
            inputId="number"
            name="number"
            placeholder="000000"
            value={number}
            onChange={handleInputChange}
          />
        </div>
        <div className="form__content__item">
          <label className="form__content__label" htmlFor="date">
            Invoice Date
          </label>
          <DateInput
            name="date"
            inputId="date"
            value={date}
            onChange={handleDateChange}
            error={fieldValidity.date == false}
            handleDateChange={handleDateChange}
          />
        </div>
        <div className="form__content__item">
          <label className="form__content__label" htmlFor="dueDate">
            Invoice Due Date
          </label>
          <DateInput
            name="dueDate"
            inputId="dueDate"
            value={dueDate}
            onChange={handleDateChange}
            error={fieldValidity.dueDate == false}
            handleDateChange={handleDateChange}
          />
        </div>
        <hr className="form__divider" />
        <div
          className={`file-upload__container ${basicInfo.stored_file ? "file-upload__container--file-attached" : ""}`}
        >
          <div className="form__heading form__heading--inline">My Upload</div>
          <FileUpload
            buttonLabel="Attach File"
            stored_file={stored_file}
            onChange={handleFileChange}
            onRemove={() => showModal("goBack")}
            acceptedFileTypes=",.csv,.txt,.tsv,.xls,.xlsx"
            disabled={fileUploadIsDisabled}
          />
        </div>
        <hr className="form__divider" />
        <div className="form__actions">
          <button className="btn btn--secondary" type="button" onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="btn btn--grow"
            style={{ marginLeft: "24px" }}
            type="submit"
            disabled={submitIsDisabled}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

NewInvoiceDrawer.propTypes = {
  customers: PropTypes.array.isRequired,
  getLatestInvoiceData: PropTypes.func.isRequired,
  hasAssignedMapping: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  setBasicInfo: PropTypes.func.isRequired,
  basicInfo: PropTypes.object.isRequired,
  handleFileChange: PropTypes.func.isRequired,
};

export default NewInvoiceDrawer;
