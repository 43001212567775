import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import useFetch from "../../hooks/useFetch";
import Notice from "../../common/Notice";
import HorizontalBar from "../../common/charts/HorizontalBar";
import ToggleSwitch from "../../common/ToggleSwitch";
import MultiSelect from "../../common/MultiSelect";
import { createQueryString } from "../../utils/urlUtils";
import { formatCurrency, addCommasToNumber } from "../../utils/numberUtils";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const DEBOUNCE_TIME = 500;
const TOGGLE_SWITCH_OPTIONS = { amount: "amount", lines: "lines" };

const { highlightBlue, lightPurple, goldenYellow, vividGreen, lightBlue, peachOrange, purpleBlue } =
  colors;

const keyLabelMappings = {
  currentInvoice: "This Invoice",
  previousInvoice: "Last Invoice",
  customerAverage: "Customer Average",
};

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const ScrubResultsComparison = ({ invoiceId, products = [] }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedToggleOption, setSelectedToggleOption] = useState(TOGGLE_SWITCH_OPTIONS.amount);
  const [notice, setNotice] = useState(initialNoticeState);
  const { get: getData, isLoading, data, error } = useFetch();

  const debouncedGetData = useCallback(debounce(getData, DEBOUNCE_TIME), []);

  useEffect(() => {
    if (error) {
      setNotice({
        kind: "error",
        open: true,
        message: "Error retrieving scrub results comparison data",
      });
    }
  }, [error]);

  useEffect(() => {
    const queryString = createQueryString({ product_id: selectedProducts.map(({ id }) => id) });

    debouncedGetData(`/api/invoices/${invoiceId}/scrub_results_comparison${queryString}`);
  }, [selectedProducts]);

  const legendKeys = data?.customerAverage?.map(({ scrubType }) => scrubType) || [];

  const barColors = [
    highlightBlue,
    lightPurple,
    goldenYellow,
    vividGreen,
    lightBlue,
    peachOrange,
    purpleBlue,
  ];

  const selectedToggleOptionIsAmount = selectedToggleOption === TOGGLE_SWITCH_OPTIONS.amount;

  const formatEntryData = (data, key, selectedToggleOptionIsAmount) => {
    return data?.[key]?.reduce((acc, curr) => {
      acc[curr.scrubType] = selectedToggleOptionIsAmount
        ? curr.rebatePercentage
        : curr.linePercentage;
      acc[`rebateAmount[${curr.scrubType}]`] = curr.rebateAmount;
      acc[`lineCount[${curr.scrubType}]`] = curr.lineCount;
      return acc;
    }, {});
  };

  const formattedData = Object.keys(data || []).map((key) => ({
    key: keyLabelMappings[key],
    ...formatEntryData(data, key, selectedToggleOptionIsAmount),
  }));

  const renderTooltip = (info) => {
    const { data, indexValue } = info;

    return (
      <>
        <div className="tooltip--position-bottom">
          <div className="tooltip__content tooltip__content--width-auto">
            <div className="tooltip__content__title" style={{ marginBottom: 16 }}>
              {indexValue}
            </div>
            {legendKeys.map((key, index) => {
              const value = selectedToggleOptionIsAmount
                ? formatCurrency(data[`rebateAmount[${key}]`]) || "$0.00"
                : addCommasToNumber(data[`lineCount[${key}]`]) || "0";

              return (
                <div
                  key={key}
                  className="tooltip__content__details tooltip__content__details--row"
                  style={{ marginBottom: 8 }}
                >
                  <div
                    className="tooltip__content__details__symbol"
                    style={{ backgroundColor: barColors[index] }}
                  />
                  <span>
                    {value} {key}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <Notice details={notice} />
      </>
    );
  };

  return (
    <div className="card" data-testid="scrub-results-comparison">
      <div className="card__actions">
        <div className="card__title">Scrub Results Comparison</div>
        <ToggleSwitch
          options={["$ Amount", "Lines"]}
          onSelection={() => {
            setSelectedToggleOption((prev) =>
              prev === TOGGLE_SWITCH_OPTIONS.amount
                ? TOGGLE_SWITCH_OPTIONS.lines
                : TOGGLE_SWITCH_OPTIONS.amount,
            );
          }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <MultiSelect
          label="Product"
          options={products.map(({ id, name }) => ({ id, label: name, value: name })).sort()}
          selectedOptions={selectedProducts}
          onChange={(selected) => setSelectedProducts(selected)}
          onClear={() => setSelectedProducts([])}
        />
      </div>
      <HorizontalBar
        data={formattedData}
        isLoading={isLoading}
        showLegend={true}
        keys={legendKeys}
        indexBy="key"
        colors={barColors}
        height={278}
        renderTooltip={renderTooltip}
        noDataMessage="There is no data that matches your filter selection"
        valueType="percent"
      />
    </div>
  );
};

ScrubResultsComparison.propTypes = {
  products: PropTypes.array.isRequired,
  invoiceId: PropTypes.string.isRequired,
};

export default ScrubResultsComparison;
