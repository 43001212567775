import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  VERTICAL_DUPLICATE,
  REQUIRED_ELEMENT,
  ALWAYS_PASS,
  ALWAYS_FAIL,
  PROBABILISTIC_340B,
  CODE_39,
  ABERRANT_QUANTITY,
  CLAIMS_340B,
} from "../contracts/contractRuleTypes";

const ruleTypes = [
  VERTICAL_DUPLICATE,
  REQUIRED_ELEMENT,
  ALWAYS_PASS,
  ALWAYS_FAIL,
  PROBABILISTIC_340B,
  CODE_39,
  ABERRANT_QUANTITY,
  CLAIMS_340B,
];

const RuleTypeComponents = {
  [VERTICAL_DUPLICATE]: ({ uniquenessColumns, setUniquenessColumns, uniquenessColumnOptions }) => (
    <FormControl fullWidth margin="normal">
      <InputLabel id="columns">Columns to Select</InputLabel>
      <Select
        labelId="columns"
        name="rule[uniqueness_columns]"
        value={uniquenessColumns}
        label="Columns to Select"
        onChange={(event) => setUniquenessColumns(event.target.value)}
      >
        <MenuItem value="">
          <em>Select</em>
        </MenuItem>
        {uniquenessColumnOptions.map((option, index) => (
          <MenuItem key={index} value={option.join()}>
            {option.join(", ")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ),
  [REQUIRED_ELEMENT]: ({ element, setElement, requiredElementOptions }) => (
    <FormControl fullWidth margin="normal">
      <InputLabel id="element">Required Element</InputLabel>
      <Select
        labelId="element"
        name="rule[element]"
        value={element}
        label="Required Element"
        onChange={(event) => setElement(event.target.value)}
      >
        <MenuItem value="">
          <em>Select</em>
        </MenuItem>
        {requiredElementOptions.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ),
  [PROBABILISTIC_340B]: ({ threshold, setThreshold }) => (
    <FormControl fullWidth margin="normal">
      <TextField
        name="rule[threshold]"
        label="Threshold"
        variant="outlined"
        value={threshold}
        type="number"
        onChange={(event) => setThreshold(event.target.value)}
      />
    </FormControl>
  ),
  [CLAIMS_340B]: ({ claimIds, setClaimId }) => (
    <FormControl fullWidth margin="normal">
      <TextField
        name="rule[claim_ids]"
        label="Claim IDs"
        variant="outlined"
        value={claimIds}
        onChange={(event) => setClaimId(event.target.value)}
      />
    </FormControl>
  ),
};

RuleTypeComponents[VERTICAL_DUPLICATE].propTypes = {
  uniquenessColumns: PropTypes.string.isRequired,
  setUniquenessColumns: PropTypes.func.isRequired,
  uniquenessColumnOptions: PropTypes.array.isRequired,
};

RuleTypeComponents[REQUIRED_ELEMENT].propTypes = {
  element: PropTypes.string.isRequired,
  setElement: PropTypes.func.isRequired,
  requiredElementOptions: PropTypes.array.isRequired,
};

RuleTypeComponents[PROBABILISTIC_340B].propTypes = {
  threshold: PropTypes.number.isRequired,
  setThreshold: PropTypes.func.isRequired,
};

RuleTypeComponents[CLAIMS_340B].propTypes = {
  claimIds: PropTypes.string.isRequired,
  setClaimId: PropTypes.func.isRequired,
};

const AddRule = ({ uniquenessColumnOptions, requiredElementOptions, rule }) => {
  const [type, setType] = useState("");
  const [uniquenessColumns, setUniquenessColumns] = useState("");
  const [element, setElement] = useState("");
  const [threshold, setThreshold] = useState("");
  const [claimIds, setClaimId] = useState("");
  const [priority, setPriority] = useState("");

  const handleTypeChange = (event) => {
    const type = event.target.value;

    switch (type) {
      case VERTICAL_DUPLICATE:
        setElement("");
        setThreshold("");
        setClaimId("");
        break;
      case REQUIRED_ELEMENT:
        setUniquenessColumns("");
        setThreshold("");
        setClaimId("");
        break;
      case PROBABILISTIC_340B:
        setUniquenessColumns("");
        setElement("");
        setClaimId("");
        break;
      case CLAIMS_340B:
        setUniquenessColumns("");
        setElement("");
        setThreshold("");
        break;
      default:
        setUniquenessColumns("");
        setElement("");
        setThreshold("");
        setClaimId("");
    }

    setType(type);
  };

  const RuleComponent = RuleTypeComponents[type] || null;

  return (
    <div className="add-default-rule">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/customers">
                Customers
              </Link>
              <Link underline="hover" color="inherit" href={`/customers/${rule.customer_id}`}>
                Customer Details
              </Link>
              <Typography color="text.primary">New Customer Rule</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={5}>
          <h1>New Customer Rule</h1>
          <form method="POST" action={`/customers/${rule.customer_id}/rules`}>
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
            <FormControl fullWidth margin="normal">
              <TextField
                name="rule[priority]"
                label="Priority"
                variant="outlined"
                value={priority}
                type="number"
                onChange={(event) => setPriority(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="type">Type</InputLabel>
              <Select
                labelId="type"
                name="rule[type]"
                value={type}
                label="Type"
                required
                onChange={handleTypeChange}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {ruleTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {RuleComponent && (
              <RuleComponent
                uniquenessColumns={uniquenessColumns}
                setUniquenessColumns={setUniquenessColumns}
                element={element}
                setElement={setElement}
                uniquenessColumnOptions={uniquenessColumnOptions}
                requiredElementOptions={requiredElementOptions}
                threshold={threshold}
                setThreshold={setThreshold}
                claimIds={claimIds}
                setClaimId={setClaimId}
              />
            )}
            <FormControl margin="normal">
              <Button variant="contained" type="submit">
                Save
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

AddRule.propTypes = {
  uniquenessColumnOptions: PropTypes.arrayOf(PropTypes.array).isRequired,
  requiredElementOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  rule: PropTypes.shape({
    customer_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default AddRule;
