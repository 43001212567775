import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { useTooltip } from "@nivo/tooltip";
import { computeXYScalesForSeries } from "@nivo/scales";
import { line } from "d3-shape";
import CustomLegend from "./CustomLegend";
import NoResults from "../../common/NoResults";
import { addCommasToNumber, formatToPercent } from "../../utils/numberUtils";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";
import textWidth from "../../utils/textWidth";
import { barTextStyle, barTheme } from "./themes";

const { lightLavender, peachOrange, purpleBlue, goldenYellow, primaryBackground } = colors;

const CHART_COLORS = {
  bars: [purpleBlue, lightLavender, goldenYellow],
  line: peachOrange,
};

const Line = ({
  bars,
  xScale,
  innerWidth,
  innerHeight,
  data = [],
  lineKey,
  renderTooltip = () => {},
}) => {
  const nivoTooltip = useTooltip();
  const barSet = bars.slice(0, data.length);
  const scale = computeXYScalesForSeries(
    [{ id: "only", data: [{ y: 1 }] }],
    { type: "linear" },
    { type: "linear" },
    innerWidth,
    innerHeight,
  );
  const lineGenerator = line()
    .x((bar) => bar.x + bar.width / 2)
    .y((bar) => scale.yScale(bar.data.data[lineKey]));

  function handleShowTooltip(event, index) {
    event.target.style.cursor = "pointer";
    nivoTooltip.showTooltipFromEvent(renderTooltip({ data: data[index] }), event);
  }

  function handleHideTooltip(event) {
    event.target.style.cursor = "default";
    nivoTooltip.hideTooltip();
  }

  return (
    <>
      <path
        className="bar__line"
        d={lineGenerator(barSet)}
        fill="none"
        stroke={CHART_COLORS.line}
        strokeWidth={2}
      />
      {barSet.map((bar, i) => {
        const value = bar.data.data[lineKey];
        const isAboveVerticalThreshold = value >= 0.94;
        // 8px (desired vertical spacing) + 3px (half of point's height) = 11px
        const labelYPosition = isAboveVerticalThreshold ? { y: 11, dy: "0.7em" } : { y: -11 };

        return (
          <g
            key={bar.key}
            transform={`translate(${xScale(bar.data.data.id) + bar.width / 2}, ${scale.yScale(value)})`}
          >
            <rect
              className="bar__line__point"
              fill={CHART_COLORS.line}
              stroke={CHART_COLORS.line}
              onMouseEnter={(e) => handleShowTooltip(e, i)}
              onMouseMove={(e) => handleShowTooltip(e, i)}
              onMouseLeave={handleHideTooltip}
            />
            <text className="bar__line__label" {...labelYPosition}>
              {formatToPercent(value)}
            </text>
          </g>
        );
      })}
    </>
  );
};

const BarWithLine = ({
  data = [],
  isLoading,
  keys,
  bottomMargin = 25,
  renderTooltip = () => {},
  renderCustomBottomTick,
  height = 400,
  barOrder,
  noDataMessage = "No data available at this time.",
}) => {
  const leftMargin = useMemo(() => {
    const barKeys = keys.bars.map((bar) => bar.key);
    const highestValue = Math.max(
      ...data.map((datum) => barKeys.reduce((accTotal, barKey) => accTotal + datum[barKey], 0)),
    );
    const estimatedLongestStr = addCommasToNumber(highestValue);
    const longestStrWidth = textWidth(
      estimatedLongestStr,
      barTextStyle.fontSize,
      barTextStyle.fontFamily,
    );

    return longestStrWidth + 16;
  }, [data]);

  const legendProps = [
    ...keys.bars.map(({ label }, i) => ({ label, color: CHART_COLORS.bars[i] })),
    { label: keys.line.label, color: CHART_COLORS.line },
  ];

  const skeletonHeight = height + 28; // 28px is the height of the legend

  function TrendingLine(props) {
    return <Line data={data} lineKey={keys.line.key} renderTooltip={renderTooltip} {...props} />;
  }

  if (!isLoading && !data.length)
    return (
      <NoResults description={noDataMessage} imgWidth={80} backgroundColor={primaryBackground} />
    );

  if (isLoading) return <Skeleton variant="rounded" height={skeletonHeight} />;

  const getBarKeys = () =>
    barOrder
      ? barOrder.map((key) => keys.bars.find((bar) => bar.key === key)?.key)
      : keys.bars.map(({ key }) => key);

  return (
    <>
      <CustomLegend legendProps={legendProps} />
      <div className="bar" style={{ height: `${height}px` }}>
        <ResponsiveBar
          data={data}
          indexBy={"id"}
          keys={getBarKeys()}
          margin={{ top: 10, right: 0, bottom: bottomMargin, left: leftMargin }}
          padding={0.3}
          valueScale={{ type: "linear", min: 0 }}
          indexScale={{ type: "band", round: true }}
          colors={(e) => {
            const indexOfIdInKeys = keys.bars.findIndex(({ key }) => key === e.id);
            return CHART_COLORS.bars[indexOfIdInKeys];
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 8,
            renderTick: renderCustomBottomTick,
          }}
          axisLeft={{
            tickValues: 4,
            tickSize: 0,
            tickPadding: 16,
            format: (val) => addCommasToNumber(val),
          }}
          gridYValues={4}
          onMouseEnter={(_, event) => (event.target.style.cursor = "pointer")}
          onMouseLeave={(_, event) => (event.target.style.cursor = "default")}
          enableLabel={false}
          tooltip={renderTooltip}
          layers={["grid", "axes", "bars", TrendingLine, "markers", "legends"]}
          theme={barTheme}
        />
      </div>
    </>
  );
};

Line.propTypes = {
  bars: PropTypes.arrayOf(PropTypes.object).isRequired,
  xScale: PropTypes.func.isRequired,
  innerWidth: PropTypes.number.isRequired,
  innerHeight: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  renderTooltip: PropTypes.func,
  lineKey: PropTypes.string.isRequired,
};

BarWithLine.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  keys: PropTypes.shape({
    bars: PropTypes.arrayOf(PropTypes.object),
    line: PropTypes.object,
  }).isRequired,
  noDataMessage: PropTypes.string,
  bottomMargin: PropTypes.number,
  renderTooltip: PropTypes.func,
  renderCustomBottomTick: PropTypes.func,
  height: PropTypes.number,
  barOrder: PropTypes.arrayOf(PropTypes.string),
};

export default BarWithLine;
