import React from "react";
import PropTypes from "prop-types";

const TextInput = ({ inputId = "", placeholder = "", name, value, onChange }) => {
  return (
    <input
      className="text-input"
      type="text"
      id={inputId}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

TextInput.propTypes = {
  inputId: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextInput;
