import React, { useState } from "react";
import PropTypes from "prop-types";
import MaterialTextInput from "../../common/inputs/MaterialTextInput";

const OtpAuth = ({ error }) => {
  const [authenticatorCode, setAuthenticatorCode] = useState("");
  const [errors, setErrors] = useState(error);

  const handleValidation = (event) => {
    if (!authenticatorCode) {
      event.preventDefault();
      setErrors("Please enter your authentication code.");
    }
  };

  return (
    <form action="/otp_secrets/verify" method="post" onSubmit={handleValidation}>
      {errors && (
        <p className="t t--error" data-testid="error-message" style={{ fontSize: "14px" }}>
          {errors}
        </p>
      )}
      <MaterialTextInput
        id="otp_attempt"
        name="otp_attempt"
        value={authenticatorCode}
        label="Authenticator Code *"
        variant="outlined"
        fullWidth
        onChange={(event) => setAuthenticatorCode(event.target.value)}
      />
      <button
        type="submit"
        style={{ backgroundColor: "#0059F5", flexGrow: 1, marginTop: "24px" }}
        className="btn btn--size-lg btn--uppercase btn--full-width"
      >
        Submit
      </button>
    </form>
  );
};

OtpAuth.propTypes = {
  error: PropTypes.string,
};

export default OtpAuth;
