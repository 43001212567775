import { isEmptyValue } from "./validationUtils";

export function numberSortFn(firstVal, secondVal, columnId) {
  const a = +firstVal.getValue(columnId);
  const b = +secondVal.getValue(columnId);

  return a === b ? 0 : a > b ? 1 : -1;
}

// filter returns true if column value exists in the array of filter values; groups `null`, `undefined`, and empty string values as `null`
export function arrIncludesSomeWithEmpty(row, columnId, filterValues) {
  if (!filterValues.length) return true;

  const value = row.getValue(columnId);
  const normalizedValue = isEmptyValue(value) ? null : value;

  return filterValues.includes(normalizedValue);
}
