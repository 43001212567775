import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button, Snackbar, Alert } from "@mui/material";
import CustomersTable from "./CustomersTable";
import { handleDeleteResource } from "../services/resourceService";

const Customers = ({ customers: initialCustomers = [] }) => {
  const [customers, setCustomers] = useState(initialCustomers);
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: "",
  });

  const handleDeleteCustomer = (customerId) => {
    handleDeleteResource(customerId, "customers")
      .then(() => {
        setCustomers(customers.filter(({ id }) => id !== customerId));
      })
      .catch(() => {
        setErrorState({
          hasError: true,
          message: "There was a problem deleting your customer. Please try again later.",
        });
      });
  };

  const handleAlertClose = () => {
    setErrorState({
      hasError: false,
      message: "",
    });
  };

  return (
    <div className="customers">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h1>Customers</h1>
            </Grid>
            <Grid item>
              <Button variant="contained" href="/customers/new">
                New Customer
              </Button>
            </Grid>
          </Grid>
          {customers.length ? (
            <CustomersTable customers={customers} handleDeleteCustomer={handleDeleteCustomer} />
          ) : (
            <p>No customers have been created.</p>
          )}
        </Grid>
      </Grid>
      <Snackbar open={errorState.hasError} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

Customers.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default Customers;
