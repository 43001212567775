import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer, notUndefined } from "@tanstack/react-virtual";
import NoResults from "../common/NoResults";
import { SortingIcons } from "../common/icons";
import TableLoadingSkeleton from "./TableLoadingSkeleton";

function TableVirtualized({
  data = [],
  columns = [],
  tableOptions = {},
  onRowClick = () => {},
  selectedRowIndex = null,
  noDataMessage = "No data available at this time.",
  isLoading = false,
  enableHover = false,
  searchQuery = "",
  filterSetup = null,
  noResultsMessage = "No results found.",
  setNotice = () => {},
  setFilteredRows = null,
  styleVariant = "",
}) {
  const [parentRefWidth, setParentRefWidth] = useState(undefined);
  const parentRef = useRef(null);

  const table = useReactTable({
    data,
    columns,
    ...tableOptions,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    if (!filterSetup || data.length === 0) return;

    table.setColumnFilters(filterSetup);

    if (setFilteredRows) {
      setTimeout(() => setFilteredRows(table.getFilteredRowModel().rows), 0);
    }
  }, [filterSetup, data]);

  const hasNoData = !data?.length;
  const showNoResultsMessage =
    !isLoading && (hasNoData || !table.getFilteredRowModel().rows.length);
  if (showNoResultsMessage) parentRef.current?.scrollTo(0, 0);

  useEffect(() => {
    setTimeout(checkHasFilterResults, 100);
  }, [searchQuery, filterSetup]);

  function checkHasFilterResults() {
    if (isLoading || hasNoData || table.getFilteredRowModel().rows.length) return;

    setNotice({
      kind: "warning",
      open: true,
      message: noResultsMessage,
    });
  }

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: table.getFilteredRowModel().rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48,
    overscan: 20,
  });

  const items = virtualizer.getVirtualItems();
  const [before, after] =
    items.length > 0
      ? [
          notUndefined(items[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() - notUndefined(items[items.length - 1]).end,
        ]
      : [0, 0];
  const colSpan = columns.length;

  const renderVirtualRows = () => {
    if (isLoading) return <TableLoadingSkeleton columns={columns.length} rows={24} />;
    return items.map((virtualRow) => {
      const row = rows[virtualRow.index];
      return (
        <tr
          key={row.id}
          style={{
            height: `${virtualRow.size}px`,
          }}
          onClick={() => onRowClick(row)}
          className={`${selectedRowIndex === row.index ? "selected" : ""}`}
        >
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} {...cell.column.columnDef.cellProps}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      );
    });
  };

  // This is used to make sure the No Results component is properly centered when the parentRef width changes.
  useEffect(() => {
    if (showNoResultsMessage) {
      const updateParentRefWidth = () => {
        setParentRefWidth(parentRef?.current.getBoundingClientRect().width - 24); // 24px is the padding on the td element
      };

      updateParentRefWidth();

      window.addEventListener("resize", updateParentRefWidth);

      return () => {
        window.removeEventListener("resize", updateParentRefWidth);
      };
    }
  }, [showNoResultsMessage]);

  return (
    <div
      ref={parentRef}
      className="table--scroll"
      style={{
        borderRadius: "8px 8px 0 0",
        height: "100%",
        overflowX: showNoResultsMessage ? "hidden" : "auto",
      }}
    >
      <table
        className={`table table--virtualized ${styleVariant} ${enableHover ? "table--hover" : ""}`}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: `${header.getSize()}px`,
                    ...header.column.columnDef.headerProps?.style,
                  }}
                  className={header.column.columnDef.headerProps?.className}
                >
                  <div>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && (
                      <span
                        data-testid={`${header.column.id}-sort-icon`}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <SortingIcons className="sort-icon" size={20} />
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {before > 0 && (
            <tr>
              <td colSpan={colSpan} style={{ height: before }} />
            </tr>
          )}
          {showNoResultsMessage ? (
            <tr>
              <td colSpan={colSpan}>
                {<NoResults description={noDataMessage} width={parentRefWidth} imgWidth={160} />}
              </td>
            </tr>
          ) : (
            renderVirtualRows()
          )}
          {after > 0 && (
            <tr>
              <td colSpan={colSpan} style={{ height: after }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

TableVirtualized.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  tableOptions: PropTypes.object,
  noDataMessage: PropTypes.string,
  onRowClick: PropTypes.func,
  selectedRowIndex: PropTypes.number,
  isLoading: PropTypes.bool,
  enableHover: PropTypes.bool,
  searchQuery: PropTypes.string,
  filterSetup: PropTypes.array,
  noResultsMessage: PropTypes.string,
  setNotice: PropTypes.func,
  setFilteredRows: PropTypes.func,
  styleVariant: PropTypes.string,
};

export default TableVirtualized;
