import React from "react";
import PropTypes from "prop-types";
import TextInput from "./TextInput";

const IconTextInput = ({ inputId = "", name, value, placeholder = "", onChange, variant }) => {
  const iconMap = {
    currency: "$",
    number: "#",
  };

  return (
    <div className="icon-text-input__container">
      <span className="icon-text-input__icon">{iconMap[variant]}</span>
      <TextInput
        inputId={inputId}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

IconTextInput.propTypes = {
  inputId: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["currency", "number"]).isRequired,
};

export default IconTextInput;
