const textWidth = (text, fontSize, fontFamily, letterSpacing = 0.25) => {
  const hasEmptyTextValue = [null, undefined, ""].includes(text);
  if (hasEmptyTextValue) return 0;

  const textString = text?.toString();
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize}px ${fontFamily}`;
  let width = context.measureText(textString).width;
  width += (textString?.length - 1 || 0) * letterSpacing;

  return width;
};

export default textWidth;
