import React from "react";
import propTypes from "prop-types";
import { styled } from "@mui/system";
import { TextField, FormControl } from "@mui/material";

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    backgroundColor: "#fff",
    borderRadius: 8,
  },
  "& label.Mui-focused": {
    color: "#3D71FF",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& fieldset": {
      borderColor: "#DEE7EE",
    },
    "&:hover fieldset": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D71FF",
    },
  },
});

const TextInput = ({
  type = "text",
  id,
  name,
  label,
  variant,
  margin = "normal",
  fullWidth = false,
  onChange,
}) => (
  <FormControl fullWidth={fullWidth}>
    <StyledTextField
      type={type}
      id={id}
      name={name}
      label={label}
      variant={variant}
      margin={margin}
      onChange={onChange}
    />
  </FormControl>
);

TextInput.propTypes = {
  type: propTypes.string,
  id: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  variant: propTypes.string.isRequired,
  margin: propTypes.string,
  fullWidth: propTypes.bool,
  onChange: propTypes.func.isRequired,
};

export default TextInput;
