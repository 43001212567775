import React, { memo } from "react";
import PropTypes from "prop-types";
import SummaryCard from "./SummaryCard";
import ScrubResultsComparison from "./ScrubResultsComparison";
import RatesOfRequiredFieldsPassingValidations from "./RatesOfRequiredFieldsPassingValidations";
import DistributionOfProducts from "./DistributionOfProducts";
import DistributionOfPlans from "./DistributionOfPlans";

const Summary = ({ invoiceId, products }) => (
  <div className="cards-container">
    <div className="cards-container cards-container--row">
      <SummaryCard invoiceId={invoiceId} />
      <ScrubResultsComparison invoiceId={invoiceId} products={products} />
    </div>
    <div className="cards-container cards-container--row">
      <div className="cards-container">
        <DistributionOfProducts invoiceId={invoiceId} />
      </div>
      <div className="cards-container">
        <RatesOfRequiredFieldsPassingValidations invoiceId={invoiceId} />
        <DistributionOfPlans invoiceId={invoiceId} />
      </div>
    </div>
  </div>
);

Summary.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
};

export default memo(Summary);
