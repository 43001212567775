import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import InvoicesTable from "./InvoicesTable";
import Search from "../common/Search";
import SelectSearch from "../common/SelectSearch";
import Notice from "../common/Notice";
import useFetch from "../hooks/useFetch";
import { Add } from "../common/icons";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const Invoices = ({ customerNames = [] }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [customerFilter, setCustomerFilter] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });
  const { isLoading, data: invoices, error } = useFetch("/api/invoices");

  useEffect(() => {
    if (error) {
      setNotice({
        kind: "error",
        open: true,
        message: "Error retrieving invoice data",
      });
    }
  }, [isLoading]);

  function updateSearchQuery(e) {
    setSearchQuery(e.target.value);
  }

  const customerOptions = [{ value: "", label: "View All" }].concat(
    customerNames.map((name) => ({ value: name, label: name })),
  );

  const statusOptions = [
    { value: "", label: "View All" },
    { value: "Processed", label: "Processed" },
    { value: "Processing", label: "Processing" },
    { value: "Upload Error", label: "Upload Error" },
    { value: "Scrub Error", label: "Scrub Error" },
  ];

  const DEBOUNCE_TIME = 500;

  return (
    <div className="main-content">
      <div className="main-content__header">
        <h1 className="main-content__heading">Invoices</h1>
      </div>
      <div className="main-content__body">
        <div className="action-bar  action-bar--align-items-flex-end action-bar--mb-16">
          <Search
            className="search--md"
            style={{ flexShrink: 0 }}
            placeholder="Search for a Customer or Invoice Number"
            onChange={debounce(updateSearchQuery, DEBOUNCE_TIME)}
          />
          <div>
            <label className="form__content__label" htmlFor="customer">
              Customer
            </label>
            <SelectSearch
              inputId="customer"
              name="customer"
              testId="customer-filter"
              options={customerOptions}
              defaultValue={customerOptions[0]}
              value={customerFilter || customerOptions[0]}
              size="md"
              onChange={(option) => setCustomerFilter(option)}
              disableDefaultSort
            />
          </div>
          <div>
            <label className="form__content__label" htmlFor="status">
              Status
            </label>
            <SelectSearch
              inputId="status"
              name="status"
              testId="status-filter"
              options={statusOptions}
              defaultValue={statusOptions[0]}
              value={statusFilter || statusOptions[0]}
              size="md"
              onChange={(option) => setStatusFilter(option)}
              disableDefaultSort
            />
          </div>
          <a className="btn action-bar__item--right" href="/invoices/new">
            <Add color={colors.white} size={20} />
            <span>Add New Invoice</span>
          </a>
        </div>
        <InvoicesTable
          invoices={invoices}
          isLoading={isLoading}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          customerFilter={customerFilter?.value}
          statusFilter={statusFilter?.value}
          setNotice={setNotice}
        />
      </div>
      <Notice details={notice} />
    </div>
  );
};

Invoices.propTypes = {
  customerNames: PropTypes.arrayOf(PropTypes.string),
};

export default Invoices;
