import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import CalculationBlock from "../../common/CalculationBlock";
import Notice from "../../common/Notice";

const SummaryCard = ({ invoiceId }) => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving summary data",
  });
  const { data, isLoading, error } = useFetch(`/api/invoices/${invoiceId}/summary`);

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  const formatData = (data) => ({
    headerValue: data.totalAmount,
    bodyValue: data.scrubbedAmount,
    footerValue: data.validatedRebateAmount,
    items: data.scrubs.map(({ scrubType, scrubbedAmount }) => ({
      label: scrubType,
      amount: scrubbedAmount,
    })),
  });

  return (
    <>
      <div className="card card--width-40pct" data-testid="summary">
        <div className="card__title">Summary</div>
        {isLoading && <Skeleton variant="rounded" height={366} />}
        {data && (
          <CalculationBlock
            headerLabel="Total Invoice Amount"
            bodyLabel="Scrubbed Amount"
            footerLabel="Validated Amount"
            data={formatData(data)}
          />
        )}
      </div>
      <Notice details={notice} />
    </>
  );
};

SummaryCard.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default SummaryCard;
