import React from "react";
import PropTypes from "prop-types";
import * as RadixTooltip from "@radix-ui/react-tooltip";

const Tooltip = ({ trigger, align = "start", alignOffset = -8, contentClass = "", children }) => {
  return (
    <RadixTooltip.Provider delayDuration={500}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger
          className="tooltip__trigger"
          onClick={(e) => e.preventDefault()}
          asChild
        >
          {trigger}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={`tooltip__content ${contentClass}`}
            side="bottom"
            alignOffset={alignOffset}
            sideOffset={6}
            align={align}
            onPointerDownOutside={(e) => e.preventDefault()}
          >
            {children}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

Tooltip.propTypes = {
  alignOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  trigger: PropTypes.node.isRequired,
  align: PropTypes.string,
  contentClass: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
