import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import { Skeleton } from "@mui/material";
import CustomLegend from "./CustomLegend";
import NoResults from "../../common/NoResults";
import { barTheme } from "./themes";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";
const { primaryBackground } = colors;

const Bar = ({
  data = [],
  groupMode = "stacked",
  indexBy,
  keys,
  height = 400,
  colors,
  showLegend = false,
  renderTooltip = () => {},
  isLoading,
  noDataMessage = "No data available at this time.",
}) => {
  const legendProps = keys.map((key, i) => ({ label: key, color: colors[i] }));

  if (!isLoading && !data.length)
    return (
      <NoResults
        description={noDataMessage}
        height={height}
        imgWidth={80}
        backgroundColor={primaryBackground}
      />
    );

  if (isLoading) {
    return <Skeleton variant="rounded" height={height} data-testid="bar-loading-skeleton" />;
  }

  return (
    <>
      {showLegend && <CustomLegend legendProps={legendProps} />}
      <div className="bar" style={{ height: `${height}px` }}>
        <ResponsiveBar
          data={data}
          isLoading={isLoading}
          indexBy={indexBy}
          keys={keys}
          margin={{ top: 10, right: 0, bottom: 25, left: 42 }}
          padding={0.3}
          innerPadding={4}
          valueScale={{ type: "linear", min: 0, max: 100 }}
          indexScale={{ type: "band", round: true }}
          colors={colors}
          axisTop={null}
          axisRight={null}
          axisBottom={{ tickSize: 0, tickPadding: 8 }}
          axisLeft={{
            tickValues: [0, 25, 50, 75, 100],
            tickSize: 0,
            tickPadding: 16,
            format: (val) => `${val}%`,
          }}
          gridYValues={[0, 25, 50, 75, 100]}
          groupMode={groupMode}
          enableLabel={false}
          onMouseEnter={(_, event) => (event.target.style.cursor = "pointer")}
          onMouseLeave={(_, event) => (event.target.style.cursor = "default")}
          tooltip={renderTooltip}
          theme={barTheme}
        />
      </div>
    </>
  );
};

Bar.propTypes = {
  data: PropTypes.array,
  indexBy: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  groupMode: PropTypes.oneOf(["stacked", "grouped"]),
  renderTooltip: PropTypes.func,
  height: PropTypes.number,
  showLegend: PropTypes.bool,
  colors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  noDataMessage: PropTypes.string,
};

export default Bar;
