import dayjs from "dayjs";

// output example: "1/1/2024"
export const formatDate = (date) => dayjs(date).format("M/D/YYYY");

// output example: "2024-01-01"
export const formatDateToFirstOfMonthISO = (date) =>
  dayjs(date).startOf("month").format("YYYY-MM-DD");

// output example: "2024-01-01"
export const formatDateToISO = (date) => dayjs(date).format("YYYY-MM-DD");

// output example: "Jan 2024"
export const formatDateShort = (date) => {
  if (isNaN(new Date(date))) return null;

  return dayjs(date).format("MMM YYYY");
};

// input example: "2024-01"
// output example: "Jan 24"
export const formatDatePartialMonthPartialYear = (date) => dayjs(date).format("MMM YY");

// input example: "2024-01"
// output example: "January 2024"
export const formatDateFullMonthFullYear = (date) => dayjs(date).format("MMMM YYYY");

// output example: "Jan 1, 2024"
export const formatDateLong = (date) => {
  if (isNaN(new Date(date))) return null;

  return dayjs(date).format("MMM D, YYYY");
};

// output example: "January 1, 2024"
export const formatDateLongWithFullMonth = (date) => {
  if (isNaN(new Date(date))) return null;

  return dayjs(date).format("MMMM D, YYYY");
};

// output example: "Jan 1, 2024 12:00 AM"
export const formatDateLongTime = (date) => {
  if (isNaN(new Date(date))) return null;

  return dayjs(date).format("MMM D, YYYY h:mm A");
};

// output example: "1/1/2024, 12:00:00 PM"
export const formatDateTimeWithMeridiem = (date) => dayjs(date).format("M/D/YYYY, h:mm:ss A");

// output example: "1/1/2024, 12:00 PM"
export const formatDateTime = (date) => dayjs(date).format("M/D/YYYY, h:mm A");

// output example: "1/1/24 12:00PM"
export const formatToCompactDateTime = (date) => {
  if (isNaN(new Date(date))) return null;

  return dayjs(date).format("M/D/YY h:mmA");
};
