import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import MultiSelect from "../common/MultiSelect";
import MetricCard from "../common/MetricCard";
import ScrubResults from "./ScrubResults";
import InvalidReasonCodesDistribution from "./InvalidReasonCodesDistribution";
import ScrubRatesByRun from "./ScrubRatesByRun";
import NoDataHorizontalBar from "../common/charts/noDataCharts/NoDataHorizontalBar";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import { addCommasToNumber, formatToPercent } from "../utils/numberUtils";
import { createQueryString } from "../utils/urlUtils";
import { extractValues } from "../utils/arrayUtils";
import { formatDateLongTime } from "../utils/dateTimeUtils";

const DEBOUNCE_TIME = 500;

const ScrubReport = ({ binaryRuns }) => {
  const [queryString, setQueryString] = useState(() => getInitialFilterState().queryParam);
  const [filteredRuns, setFilteredRuns] = useState(() => getInitialFilterState().filteredRuns);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving scrub report data",
  });
  const metricCardTime = useRef(undefined);

  const { isLoading, data, error, get: getData } = useFetch();

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  useEffect(() => {
    debouncedFetch(`/api/reports/scrub_rate_report${queryString}`, hasActiveFilter);
  }, [queryString]);

  const runOptions = useMemo(() => {
    if (!binaryRuns) return [];

    return Object.entries(binaryRuns).map(([run, runDate]) => ({
      value: run,
      label: formatDateLongTime(runDate),
    }));
  }, []);

  const debouncedFetch = useCallback(
    debounce(async (endpoint, hasActiveFilter) => {
      await getData(endpoint);
      metricCardTime.current = hasActiveFilter ? "Current Selection" : undefined;
    }, DEBOUNCE_TIME),
    [],
  );

  const scrubRateData = data?.[0] || {};
  const formattedScrubRate = formatToPercent(scrubRateData.totalScrubRate);
  const formattedTotalRebates = addCommasToNumber(scrubRateData.totalLines);
  const hasActiveFilter = Boolean(filteredRuns.length);

  function getInitialFilterState() {
    const urlParams = new URLSearchParams(window.location.search);
    const runParam = urlParams.get("run");
    const runDate = formatDateLongTime(binaryRuns?.[runParam]);
    if (!runParam || !runDate) return { queryParam: "", filteredRuns: [] };

    return {
      queryParam: createQueryString({ run: [runParam] }),
      filteredRuns: [{ value: runParam, label: runDate }],
    };
  }

  function handleFilterChange(selectedFilters) {
    setFilteredRuns(selectedFilters);
    setQueryString(createQueryString({ run: extractValues(selectedFilters) }));
  }

  function handleClearFilter() {
    setFilteredRuns([]);
    setQueryString("");
  }

  return (
    <>
      <div className="main-content__header">
        <span className="main-content__heading">Scrub Report</span>
      </div>
      <div className="main-content__body">
        <div className="action-bar">
          <MultiSelect
            label="Run"
            options={runOptions}
            selectedOptions={filteredRuns}
            onChange={handleFilterChange}
            onClear={handleClearFilter}
            disableDefaultSort
          />
          {hasActiveFilter && (
            <button className="btn btn--plain" onClick={handleClearFilter}>
              Clear All
            </button>
          )}
        </div>
        <div className="cards-container cards-container--row">
          <div className="cards-container">
            <div className="cards-container cards-container--row">
              <MetricCard
                title="Scrub Rate"
                value={formattedScrubRate}
                time={metricCardTime.current}
                isLoading={isLoading}
              />
              <MetricCard
                title="Total Rebates"
                value={formattedTotalRebates}
                time={metricCardTime.current}
                isLoading={isLoading}
              />
            </div>
            <ScrubResults data={scrubRateData} isLoading={isLoading} />
            <InvalidReasonCodesDistribution queryString={queryString} />
          </div>
          <div className="cards-container">
            <ScrubRatesByRun queryString={queryString} />
            <div className="card">
              <span className="card__title">Scrub Rates by Product</span>
              <NoDataHorizontalBar height="200" />
            </div>
            <div className="card">
              <span className="card__title">Scrub Rates by Customer</span>
              <NoDataHorizontalBar height="200" />
            </div>
          </div>
        </div>
      </div>
      <Notice details={notice} />
    </>
  );
};

ScrubReport.propTypes = {
  binaryRuns: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ScrubReport;
