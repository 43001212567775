import React from "react";
import { propTypes } from "./iconProps";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { notice } = colors;

const Error = ({ color = notice, size = "20px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox="0 0 20 20"
    width={size}
    fill={color}
  >
    <path
      d="M10 10.833c.236 0 .434-.08.595-.24.159-.159.239-.357.239-.593V6.646c0-.236-.08-.43-.24-.583a.827.827 0 0 0-.594-.23.806.806 0 0 0-.593.24.807.807 0 0 0-.24.594v3.354c0 .236.08.43.24.583.16.153.357.23.593.23zm0 3.334c.236 0 .434-.08.595-.24.159-.16.239-.357.239-.594a.809.809 0 0 0-.24-.594A.809.809 0 0 0 10 12.5a.806.806 0 0 0-.593.24.807.807 0 0 0-.24.593c0 .236.08.434.24.594.16.16.357.24.593.24zm0 4.166a8.11 8.11 0 0 1-3.25-.656 8.41 8.41 0 0 1-2.646-1.781 8.41 8.41 0 0 1-1.78-2.646A8.11 8.11 0 0 1 1.667 10a8.11 8.11 0 0 1 .657-3.25 8.41 8.41 0 0 1 1.78-2.646A8.421 8.421 0 0 1 6.75 2.323 8.12 8.12 0 0 1 10 1.667a8.12 8.12 0 0 1 3.25.656 8.421 8.421 0 0 1 2.646 1.781 8.41 8.41 0 0 1 1.781 2.646 8.11 8.11 0 0 1 .657 3.25 8.11 8.11 0 0 1-.657 3.25 8.409 8.409 0 0 1-1.78 2.646 8.41 8.41 0 0 1-2.647 1.78 8.11 8.11 0 0 1-3.25.657zm0-1.666c1.848 0 3.42-.65 4.72-1.948 1.298-1.299 1.947-2.872 1.947-4.719 0-1.847-.65-3.42-1.948-4.72C13.421 3.984 11.848 3.334 10 3.334c-1.847 0-3.42.65-4.718 1.948C3.983 6.58 3.334 8.153 3.334 10c0 1.846.65 3.42 1.948 4.718C6.58 16.018 8.153 16.667 10 16.667z"
      fill="#E91E63"
    />
  </svg>
);

Error.propTypes = propTypes;

export default Error;
