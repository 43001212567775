import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const LoadingSkeletonWrapper = ({ isLoading = false, children = <></>, width = "80%" }) => {
  if (isLoading) return <Skeleton animation="wave" width={width} />;

  return children;
};

LoadingSkeletonWrapper.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LoadingSkeletonWrapper;
