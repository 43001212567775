import React from "react";
import PropTypes from "prop-types";
import SelectSearch from "../../common/SelectSearch";
import NewInvoiceTable from "./NewInvoiceTable";
import Indicator from "../../common/Indicator";

const SelectWorksheet = ({
  data,
  worksheets,
  selectedSheetName,
  onSelectWorksheet,
  headerRowIndex,
  setHeaderRowIndex = () => {},
  isTextBasedFileType,
  onConfirm,
}) => {
  const hasSelectedWorksheet = Boolean(selectedSheetName || isTextBasedFileType);

  return (
    <div className="select-worksheet">
      <div className="select-worksheet__subheading">Worksheet and Header Row Selection</div>
      <div className="select-worksheet__section select-worksheet__section--shrink-0">
        <div className="select-worksheet__section__instruction">
          <Indicator isVisible={!hasSelectedWorksheet} testId="select-worksheet-indicator" />
          <span>Select the worksheet that contains the invoice data you want to import</span>
        </div>
        <div className="select-worksheet__section__body">
          <label htmlFor="worksheet-select">Worksheet</label>
          <SelectSearch
            testId="worksheet-select"
            inputId="worksheet-select"
            options={worksheets}
            emptyOptionLabel={isTextBasedFileType ? "No Worksheets" : "Choose Worksheet"}
            placeholder={isTextBasedFileType ? "No Worksheets" : "Choose Worksheet"}
            size="lg"
            disabled={isTextBasedFileType}
            value={{ label: selectedSheetName, value: selectedSheetName }}
            onChange={onSelectWorksheet}
          />
        </div>
      </div>
      {hasSelectedWorksheet && (
        <div className="select-worksheet__section">
          <div className="select-worksheet__section__instruction">
            <Indicator testId="select-header-indicator" />
            <span>Click the row below that contains the header values for your data</span>
          </div>
          <div className="select-worksheet__section__body">
            <NewInvoiceTable
              headerRowIndex={headerRowIndex}
              setHeaderRowIndex={setHeaderRowIndex}
              data={data}
              onConfirm={onConfirm}
            />
          </div>
        </div>
      )}
    </div>
  );
};

SelectWorksheet.propTypes = {
  data: PropTypes.array.isRequired,
  worksheets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  selectedSheetName: PropTypes.string,
  onSelectWorksheet: PropTypes.func.isRequired,
  headerRowIndex: PropTypes.number,
  setHeaderRowIndex: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  isTextBasedFileType: PropTypes.bool.isRequired,
};

export default SelectWorksheet;
