import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { ArrowRight } from "./icons";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const Breadcrumbs = ({ breadcrumbs, handleBreadcrumbClick }) => {
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => {
        const isActiveStep = Object.keys(breadcrumbs).length - 1 === index;

        return (
          <Fragment key={index}>
            <button
              className={`breadcrumbs__item ${isActiveStep ? "breadcrumbs__item--active" : ""}`}
              onClick={() => handleBreadcrumbClick(breadcrumb)}
            >
              <span>{breadcrumb}</span>
            </button>
            {isActiveStep ? null : (
              <span
                key={`separator-${index}`}
                className="breadcrumbs__separator"
                data-testid="breadcrumb-separator"
              >
                <ArrowRight color={colors.secondary} size={20} />
              </span>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleBreadcrumbClick: PropTypes.func.isRequired,
};

export default Breadcrumbs;
