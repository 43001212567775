import React from "react";
import PropTypes from "prop-types";

const Badge = ({ error = false, enableHover = false, children }) => {
  return (
    <div className={`badge ${enableHover ? "badge--hover" : ""}`}>
      <div className={`badge__indicator ${error ? "badge__indicator--error" : ""}`} />
      {children}
    </div>
  );
};

Badge.propTypes = {
  error: PropTypes.bool,
  enableHover: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default Badge;
