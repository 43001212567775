import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { Error } from "../../common/icons";

const DateInput = ({ inputId = "", name, value, onChange, error = false }) => {
  const renderError = () => (
    <div className="text-input__error-msg">
      <Error />
      <span>Invalid date</span>
    </div>
  );

  return (
    <>
      <InputMask
        className={`text-input ${error ? "text-input--error" : ""}`}
        id={inputId}
        name={name}
        value={value}
        mask="99 / 99 / 9999"
        placeholder="MM / DD / YYYY"
        maskChar={null}
        onChange={onChange}
      />
      {error && renderError()}
    </>
  );
};

DateInput.propTypes = {
  inputId: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default DateInput;
