import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import Donut from "../common/charts/Donut";
import LoadingSkeletonWrapper from "../common/LoadingSkeletonWrapper";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import { addCommasToNumber, formatToPercent } from "../utils/numberUtils";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const CHART_ITEM_COLOR_MAP = {
  "Claim Number Not Populated": colors.vividGreen,
  "NDC Not Populated": colors.lightBlue,
  "NDC Invalid": colors.peachOrange,
  "SPID/NPI Not Populated": colors.purpleBlue,
  "SPID/NPI Invalid": colors.vividBurgundy,
  "Plan ID Not Populated": colors.primary,
  "DOS Not Populated": colors.brightOrange,
  "DOS Invalid": colors.darkBlue,
};

const DEBOUNCE_TIME = 500;

const InvalidReasonCodesDistribution = ({ queryString }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving invalid reason code data",
  });

  const { isLoading, data, error, get: getData } = useFetch();

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  useEffect(() => {
    debouncedFetch(`/api/reports/invalid_reason_codes_report${queryString}`);
  }, [queryString]);

  const debouncedFetch = useCallback(debounce(getData, DEBOUNCE_TIME), []);

  const formattedTotals = useMemo(() => {
    const totals = data?.reduce(
      (acc, curr) => ({
        lines: acc.lines + curr.totalLines,
        percentOfAllLines: acc.percentOfAllLines + curr.percentOfAllLines,
        percentOfInvalidLines: acc.percentOfInvalidLines + curr.percentOfInvalidLines,
      }),
      { lines: 0, percentOfAllLines: 0, percentOfInvalidLines: 0 },
    );

    return {
      totalLines: addCommasToNumber(totals?.lines),
      percentOfAllLines: formatToPercent(totals?.percentOfAllLines, 1),
      percentOfInvalidLines: formatToPercent(totals?.percentOfInvalidLines, 1),
    };
  }, [data]);

  const donutChartData = useMemo(() => {
    return data?.map((datum) => ({
      id: datum.reasonCode,
      value: datum.totalLines,
      color: CHART_ITEM_COLOR_MAP[datum.reasonCode],
      percentOfInvalidLines: datum.percentOfInvalidLines,
    }));
  }, [data]);

  const tableData = isLoading ? Array(8).fill({}) : data;

  function renderCenterText({ centerX, centerY }) {
    const currHoveredObj = donutChartData?.find(({ id }) => id === activeItem);
    const centerText = currHoveredObj
      ? formatToPercent(currHoveredObj?.percentOfInvalidLines)
      : null;

    return (
      <text className="donut__center-text" x={centerX} y={centerY} dominantBaseline="central">
        {centerText}
      </text>
    );
  }

  return (
    <div className="card" data-testid="invalid-reason-codes-distribution">
      <div className="card__title">Invalid Reason Codes Distribution</div>
      <div className="donut__container">
        <Donut
          data={donutChartData}
          isLoading={isLoading}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          renderCenterText={renderCenterText}
        />
        {tableData?.length && (
          <div className="donut__table">
            <div className="donut__table__row donut__table__row--header">
              <div className="donut__table__row__item--min-width-30">Reason Code</div>
              <div className="donut__table__row__item--align-right">Lines</div>
              <div className="donut__table__row__item--align-right">% of All Lines</div>
              <div className="donut__table__row__item--align-right">% of Invalid</div>
            </div>
            {tableData?.map((datum, i) => (
              <div
                key={datum.reasonCode || i}
                className={`donut__table__row donut__table__row--body ${activeItem === datum.reasonCode ? "donut__table__row--active" : ""}`}
                onMouseEnter={() => setActiveItem(datum.reasonCode)}
                onMouseLeave={() => setActiveItem(null)}
              >
                <div className="donut__table__body__item--id donut__table__row__item--min-width-30">
                  <LoadingSkeletonWrapper isLoading={isLoading}>
                    <div style={{ backgroundColor: CHART_ITEM_COLOR_MAP[datum.reasonCode] }} />
                    <span>{datum.reasonCode}</span>
                  </LoadingSkeletonWrapper>
                </div>
                <div className="donut__table__row__item--align-right">
                  <LoadingSkeletonWrapper isLoading={isLoading}>
                    {addCommasToNumber(datum.totalLines)}
                  </LoadingSkeletonWrapper>
                </div>
                <div className="donut__table__row__item--align-right">
                  <LoadingSkeletonWrapper isLoading={isLoading}>
                    {formatToPercent(datum.percentOfAllLines, 1)}
                  </LoadingSkeletonWrapper>
                </div>
                <div className="donut__table__row__item--align-right">
                  <LoadingSkeletonWrapper isLoading={isLoading}>
                    {formatToPercent(datum.percentOfInvalidLines, 1)}
                  </LoadingSkeletonWrapper>
                </div>
              </div>
            ))}
            <div className="donut__table__row donut__table__row--footer">
              <div className="donut__table__row__item--min-width-30">Subtotal</div>
              <div className="donut__table__row__item--align-right">
                <LoadingSkeletonWrapper isLoading={isLoading}>
                  {formattedTotals.totalLines}
                </LoadingSkeletonWrapper>
              </div>
              <div className="donut__table__row__item--align-right">
                <LoadingSkeletonWrapper isLoading={isLoading}>
                  {formattedTotals.percentOfAllLines}
                </LoadingSkeletonWrapper>
              </div>
              <div className="donut__table__row__item--align-right">
                <LoadingSkeletonWrapper isLoading={isLoading}>
                  {formattedTotals.percentOfInvalidLines}
                </LoadingSkeletonWrapper>
              </div>
            </div>
          </div>
        )}
      </div>
      <Notice details={notice} />
    </div>
  );
};

InvalidReasonCodesDistribution.propTypes = {
  queryString: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default InvalidReasonCodesDistribution;
