import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useFetch from "../../hooks/useFetch";
import Notice from "../../common/Notice";
import Bar from "../../../components/common/charts/Bar";
import { addCommasToNumber } from "../../utils/numberUtils";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { peachOrange, primary } = colors;

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const RatesOfRequiredFieldsPassingValidations = ({ invoiceId }) => {
  const [notice, setNotice] = useState(initialNoticeState);

  const { isLoading, data, error } = useFetch(`/api/invoices/${invoiceId}/validated_field_rates`);

  useEffect(() => {
    if (error) {
      setNotice({
        kind: "error",
        open: true,
        message: "Error retrieving rates of required fields passing validations data",
      });
    }
  }, [error]);

  const formattedData = data?.map((datum) => ({
    key: datum.field,
    "Last Invoice": Math.round(datum.previousInvoice?.percentage * 100),
    "This Invoice": Math.round(datum.currentInvoice?.percentage * 100),
    totalLines: {
      "Last Invoice": datum.previousInvoice?.lines,
      "This Invoice": datum.currentInvoice?.lines,
    },
  }));

  const renderTooltip = (info) => {
    const {
      data: { key, totalLines },
      color,
      value,
      id,
    } = info;
    return (
      <div className="tooltip--position-bottom">
        <div className="tooltip__content">
          <div className="tooltip__content__title">{key}</div>
          <div className="tooltip__content__details tooltip__content__details--row">
            <span
              className="tooltip__content__details__symbol"
              style={{ backgroundColor: color }}
            />
            <span>
              {value}% | {addCommasToNumber(totalLines[id])} Lines
            </span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="card" data-testid="rates-of-required-fields-passing-validations">
        <div className="card__title">Rates of Required Fields Passing Validations</div>
        <Bar
          isLoading={isLoading}
          data={formattedData}
          indexBy="key"
          keys={["Last Invoice", "This Invoice"]}
          groupMode="grouped"
          colors={[peachOrange, primary]}
          showLegend={true}
          valueType="percent"
          height={278}
          renderTooltip={renderTooltip}
          noDataMessage="There is no data that matches your filter selection"
        />
      </div>
      <Notice details={notice} />
    </>
  );
};

RatesOfRequiredFieldsPassingValidations.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default RatesOfRequiredFieldsPassingValidations;
