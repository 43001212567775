import React, { useState } from "react";
import PropTypes from "prop-types";
import MaterialTextInput from "../../common/inputs/MaterialTextInput";

const OtpAuthSetup = ({ otpSecret, error }) => {
  const [authenticatorCode, setAuthenticatorCode] = useState("");
  const [errors, setErrors] = useState(error);

  const handleValidation = (event) => {
    if (!authenticatorCode) {
      event.preventDefault();
      setErrors("Please enter your authentication code.");
    }
  };

  const handleLogout = () => {
    fetch("/logout", {
      method: "DELETE",
    })
      .then((res) => {
        if (res.redirected) {
          Turbo.visit(res.url);
        } else if (!res.ok) {
          throw new Error("Error logging out");
        } else {
          Turbo.visit("/");
        }
      })
      .catch((err) => setErrors(err.message));
  };

  return (
    <form
      action="/otp_secrets"
      method="post"
      onSubmit={handleValidation}
      data-testid="authenticate-form"
    >
      <input type="hidden" id="token" name="otp_secret" value={otpSecret} />
      {errors && (
        <p className="t t--error" data-testid="error-message" style={{ fontSize: "14px" }}>
          {errors}
        </p>
      )}
      <MaterialTextInput
        id="otp_attempt"
        name="otp_attempt"
        value={authenticatorCode}
        label="Authenticator Code *"
        variant="outlined"
        fullWidth
        onChange={(event) => setAuthenticatorCode(event.target.value)}
      />
      <div style={{ display: "flex", marginTop: "24px" }}>
        <button
          type="button"
          onClick={handleLogout}
          className="btn btn--secondary btn--size-lg btn--uppercase"
          style={{ marginRight: "16px" }}
        >
          Logout
        </button>
        <button
          type="submit"
          style={{ backgroundColor: "#0059F5", flexGrow: 1 }}
          className="btn btn--size-lg btn--uppercase"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

OtpAuthSetup.propTypes = {
  otpSecret: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default OtpAuthSetup;
