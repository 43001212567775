import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import BarWithLine from "../common/charts/BarWithLine";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import { barTextStyle } from "../common/charts/themes";
import { addCommasToNumber, formatToPercent } from "../utils/numberUtils";
import { formatToCompactDateTime } from "../utils/dateTimeUtils";
import textWidth from "../utils/textWidth";

const KEYS = {
  bars: [
    { key: "scrubbed", label: "Scrubbed" },
    { key: "not_scrubbed", label: "Not Scrubbed" },
    { key: "invalid", label: "Invalid" },
  ],
  line: { key: "scrub_rate", label: "Scrub Rate" },
};

const DEBOUNCE_TIME = 500;

const ScrubRatesByRun = ({ queryString }) => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving scrub rates by run data",
  });

  const { isLoading, data, error, get: getData } = useFetch();

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  useEffect(() => {
    debouncedFetch(`/api/binary_runs${queryString}`);
  }, [queryString]);

  const debouncedFetch = useCallback(debounce(getData, DEBOUNCE_TIME), []);

  const formattedData = useMemo(
    () => data?.map((obj) => ({ ...obj, id: formatToCompactDateTime(obj.date) })).reverse(),
    [data],
  );

  const chartBottomMargin = useMemo(() => {
    if (!data?.length) return 0;

    const largestTextWidth = formattedData.reduce((largestWidth, { id }) => {
      if (!id) return largestWidth;

      const [date, time] = id.split(" ");
      const dateTextWidth = textWidth(date, barTextStyle.fontSize, barTextStyle.fontFamily);
      const timeTextWidth = textWidth(time, barTextStyle.fontSize, barTextStyle.fontFamily);

      return Math.max(largestWidth, dateTextWidth, timeTextWidth);
    }, 0);

    return largestTextWidth + 8; // added 8 for desired tick padding
  }, [data]);

  function renderTooltip({ data: { id, scrubbed, rebates, scrub_rate } }) {
    return (
      <div className="tooltip--position-bottom">
        <div className="tooltip__content">
          <div className="tooltip__content__title">{id}</div>
          <div className="tooltip__content__details">
            <span>{`${addCommasToNumber(scrubbed)} Scrubbed`}</span>
            <span>{`${addCommasToNumber(rebates)} Total Lines`}</span>
            <span>{`${formatToPercent(scrub_rate)} Scrub Rate`}</span>
          </div>
        </div>
      </div>
    );
  }

  function renderCustomBottomTick({ opacity, textX, textY, value, x, y }) {
    const [date, time] = value.split(" ");
    const tickRotation = -90;

    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <text
          alignmentBaseline="central"
          transform={`translate(${textX - 5},${textY + chartBottomMargin - 8}) rotate(${tickRotation})`}
          style={barTextStyle}
        >
          {date}
        </text>
        <text
          alignmentBaseline="central"
          transform={`translate(${textX + 5},${textY + chartBottomMargin - 8}) rotate(${tickRotation})`}
          style={barTextStyle}
        >
          {time}
        </text>
      </g>
    );
  }

  return (
    <div className="card" data-testid="scrub-rates-by-run">
      <div className="card__title">Scrub Rates by Run</div>
      <BarWithLine
        data={formattedData}
        isLoading={isLoading}
        keys={KEYS}
        barOrder={["scrubbed", "invalid", "not_scrubbed"]}
        bottomMargin={chartBottomMargin}
        renderTooltip={renderTooltip}
        renderCustomBottomTick={renderCustomBottomTick}
        height={185}
      />
      <Notice details={notice} />
    </div>
  );
};

ScrubRatesByRun.propTypes = {
  queryString: PropTypes.string.isRequired,
};

export default ScrubRatesByRun;
