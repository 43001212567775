import React from "react";
import PropTypes from "prop-types";
import NameValuePair from "../../common/NameValuePair";
import { Download } from "../../common/icons";
import { formatCurrency } from "../../utils/numberUtils";
import { formatDateLongWithFullMonth } from "../../utils/dateTimeUtils";

import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { neutralGray, white } = colors;

const Metadata = ({ invoice: { id, validated_rebate_amount, customer_name, date, due_date } }) => {
  const nameValuePairs = [
    { name: "Customer", value: customer_name },
    { name: "Invoice Date", value: formatDateLongWithFullMonth(date) },
    { name: "Due Date", value: formatDateLongWithFullMonth(due_date) },
  ];

  return (
    <div
      className="card card--secondary"
      style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
    >
      <div>
        <NameValuePair
          name="Validated Amount"
          value={formatCurrency(validated_rebate_amount)}
          isLargeValue
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {nameValuePairs.map(({ name, value }, index) => (
          <div key={index} style={{ padding: "0 48px", borderRight: `1px solid ${neutralGray}` }}>
            <NameValuePair name={name} value={value} />
          </div>
        ))}
        <a
          className="btn"
          style={{ marginLeft: "64px" }}
          href={`/invoices/${id}/export`}
          data-turbo="false"
        >
          <Download color={white} size={20} />
          <span>Download</span>
        </a>
      </div>
    </div>
  );
};

Metadata.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string,
    validated_rebate_amount: PropTypes.string,
    customer_name: PropTypes.string,
    date: PropTypes.string,
    due_date: PropTypes.string,
  }).isRequired,
};

export default Metadata;
