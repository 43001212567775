import React, { useRef } from "react";
import PropTypes from "prop-types";
import { FilePresent, Trash } from "./icons";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const getFileType = (name) => name?.split(".").pop();

const FileUpload = ({
  buttonLabel,
  stored_file,
  onChange,
  onRemove,
  acceptedFileTypes,
  disabled = false,
}) => {
  const fileUploadRef = useRef();
  const { name } = stored_file || { name: "" };

  const handleKeyDown = (event) => {
    if (fileUploadRef.current && event.key === "Enter") {
      fileUploadRef.current.click();
    }
  };

  const handleFileAttachment = (event) => {
    onChange(event.target.files[0], getFileType(event.target.files[0].name));
  };

  return (
    <div className="file-upload">
      {!stored_file ? (
        <>
          <label
            htmlFor="file-upload"
            className={`file-upload__label ${disabled ? "file-upload__label--disabled" : ""}`}
            tabIndex={0}
            onKeyDown={(event) => handleKeyDown(event)}
            ref={fileUploadRef}
          >
            {buttonLabel}
          </label>
          <input
            id="file-upload"
            type="file"
            accept={acceptedFileTypes}
            disabled={disabled}
            onChange={handleFileAttachment}
          />
        </>
      ) : (
        <div className="file-upload__attachment">
          <div className="file-upload__attachment__details">
            <FilePresent color={colors.secondary} size={24} />
            <div className="file-upload__attachment__name" title={name}>
              {name}
            </div>
          </div>
          <div className="file-upload__attachment__remove-attachment">
            <button type="button" className="icon-btn" onClick={onRemove} data-testid="delete-btn">
              <Trash color={colors.secondary} size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  stored_file: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default FileUpload;
