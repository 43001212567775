import React, { useState } from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "../../common/Breadcrumbs";
import Metadata from "./Metadata";
import Tabs from "../../common/Tabs";
import Summary from "./Summary";
import Spreadsheet from "./Spreadsheet";

const tabItems = {
  SUMMARY: "Summary",
  SPREADSHEET: "Spreadsheet",
};

const InvoiceDetails = ({ invoice, products, scrubRules }) => {
  const [selectedTab, setSelectedTab] = useState(tabItems.SUMMARY);

  return (
    <div className="main-content">
      <div className="main-content__header">
        <Breadcrumbs
          breadcrumbs={["Invoices", "Invoice Details"]}
          handleBreadcrumbClick={() => (window.location.href = "/invoices")}
        />
        <h1 className="main-content__heading">{`${invoice.customer_name} ${invoice.number}`}</h1>
      </div>
      <div className="main-content__body" style={{ overflowY: "hidden" }}>
        <Metadata invoice={invoice} />
        <Tabs
          items={Object.values(tabItems)}
          selectedItem={selectedTab}
          setSelectedItem={setSelectedTab}
        />
        <div
          style={{
            display: selectedTab === tabItems.SUMMARY ? "block" : "none",
            overflowY: "auto",
          }}
        >
          <Summary invoiceId={invoice.id} products={products} />
        </div>
        <div
          style={{
            display: selectedTab === tabItems.SPREADSHEET ? "flex" : "none",
            flexDirection: "column",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          <Spreadsheet
            invoiceId={invoice.id}
            scrubRules={scrubRules}
            isDisplayed={selectedTab === tabItems.SPREADSHEET}
          />
        </div>
      </div>
    </div>
  );
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    due_date: PropTypes.string.isRequired,
    total_amount: PropTypes.string.isRequired,
    calculated_rebate_amount: PropTypes.string.isRequired,
    validated_rebate_amount: PropTypes.string.isRequired,
    calculation_variance: PropTypes.string.isRequired,
    scrubbed_amount: PropTypes.string.isRequired,
    customer_name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    files: PropTypes.array.isRequired,
  }).isRequired,
  products: PropTypes.array.isRequired,
  scrubRules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

export default InvoiceDetails;
