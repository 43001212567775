import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { formatDate, formatDateTimeWithMeridiem } from "../utils/dateTimeUtils";
import { handleDeleteResource } from "../services/resourceService";

const Contracts = ({ contracts: initialContracts = [] }) => {
  const [contracts, setContracts] = useState(initialContracts);
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: "",
  });

  const handleDeleteContract = (contractId) => {
    handleDeleteResource(contractId, "contracts")
      .then(() => {
        setContracts(contracts.filter(({ id }) => id !== contractId));
      })
      .catch(() => {
        setErrorState({
          hasError: true,
          message: "There was a problem deleting your contract. Please try again later.",
        });
      });
  };

  const handleAlertClose = () => {
    setErrorState({
      hasError: false,
      message: "",
    });
  };

  return (
    <div className="contracts">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h1>Contracts</h1>
            </Grid>
            <Grid item>
              <Button variant="contained" href="/contracts/new">
                New Contract
              </Button>
            </Grid>
          </Grid>
          {contracts.length ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Effective Date</TableCell>
                    <TableCell>Expiration Date</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contracts.map(
                    ({ id, effective_date, expiration_date, name, created_at, customer }) => (
                      <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell>
                          <Link href={`/contracts/${id}`}>{name}</Link>
                        </TableCell>
                        <TableCell>{customer.name}</TableCell>
                        <TableCell>{formatDate(effective_date)}</TableCell>
                        <TableCell>{formatDate(expiration_date)}</TableCell>
                        <TableCell>{formatDateTimeWithMeridiem(created_at)}</TableCell>
                        <TableCell align="right">
                          <Button href={`/contracts/${id}/edit`}>Edit</Button>
                          <Button
                            onClick={() => handleDeleteContract(id)}
                            variant="outlined"
                            color="error"
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <p>No contracts have been created.</p>
          )}
        </Grid>
      </Grid>
      <Snackbar open={errorState.hasError} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

Contracts.propTypes = {
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      effective_date: PropTypes.string,
      expiration_date: PropTypes.string,
      customer: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
};

export default Contracts;
