import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "../utils/numberUtils";

const CalculationBlock = ({
  headerLabel,
  bodyLabel,
  footerLabel,
  data: { headerValue = 0, bodyValue = 0, footerValue = 0, items },
}) => {
  const hasItems = items.length > 0;

  return (
    <>
      <div className="calculation-block" data-testid="calculation-block">
        <div className="calculation-block__item">
          <div className="calculation-block__label">{headerLabel}</div>
          <div className="calculation-block__value">{formatCurrency(headerValue)}</div>
        </div>
      </div>
      <div className="calculation-block">
        <div
          className={`calculation-block__item ${hasItems ? "calculation-block__item--bottom-border" : ""}`}
        >
          <div className="calculation-block__label">{bodyLabel}</div>
          <div className="calculation-block__value">{formatCurrency(bodyValue)}</div>
        </div>
        {hasItems &&
          items.map(({ label, amount }, index) => (
            <div className="calculation-block__item calculation-block__item--detail" key={index}>
              <div className="calculation-block__label">{label}</div>
              <div className="calculation-block__value">{formatCurrency(amount || 0)}</div>
            </div>
          ))}
      </div>
      <div className="calculation-block calculation-block--secondary">
        <div className="calculation-block__item">
          <div className="calculation-block__label--secondary">{footerLabel}</div>
          <div className="calculation-block__value--secondary">{formatCurrency(footerValue)}</div>
        </div>
      </div>
    </>
  );
};

CalculationBlock.propTypes = {
  headerLabel: PropTypes.string.isRequired,
  bodyLabel: PropTypes.string.isRequired,
  footerLabel: PropTypes.string.isRequired,
  data: PropTypes.shape({
    headerValue: PropTypes.string.isRequired,
    bodyValue: PropTypes.string.isRequired,
    footerValue: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default CalculationBlock;
