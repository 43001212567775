import React, { useState } from "react";
import PropTypes from "prop-types";
import Donut from "../common/charts/Donut";
import { addCommasToNumber, formatToPercent } from "../utils/numberUtils";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";
import LoadingSkeletonWrapper from "../common/LoadingSkeletonWrapper";

const { highlightBlue, lightPurple, goldenYellow } = colors;

const ScrubResults = ({ data = {}, isLoading = false }) => {
  const [activeItem, setActiveItem] = useState(null);

  const donutChartData = [
    {
      id: "Scrubbed",
      value: data?.totalScrubbed,
      percentOfTotal: data?.totalScrubRate,
      color: highlightBlue,
    },
    {
      id: "Not Scrubbed",
      value: data?.totalNotScrubbed,
      percentOfTotal: data?.totalNotScrubbedRate,
      color: lightPurple,
    },

    {
      id: "Invalid",
      value: data?.totalInvalid,
      percentOfTotal: data?.totalInvalidRate,
      color: goldenYellow,
    },
  ];

  function renderCenterText({ centerX, centerY }) {
    const currHoveredObj = donutChartData?.find(({ id }) => id === activeItem);
    const centerText = formatToPercent(currHoveredObj?.percentOfTotal);

    return (
      <text className="donut__center-text" x={centerX} y={centerY} dominantBaseline="central">
        {centerText}
      </text>
    );
  }

  const totalPercent = data?.totalScrubRate + data?.totalNotScrubbedRate + data?.totalInvalidRate;

  const hasData = Boolean(Object.keys(data).length);

  return (
    <div className="card" data-testid="scrub-results">
      <div className="card__title">Scrub Results</div>
      <div className="donut__container">
        <Donut
          data={hasData ? donutChartData : []}
          isLoading={isLoading}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          renderCenterText={renderCenterText}
        />
        {(isLoading || hasData) && (
          <div className="donut__table">
            <div className="donut__table__row donut__table__row--header">
              <div>Status</div>
              <div className="donut__table__row__item--align-right">Lines</div>
              <div className="donut__table__row__item--align-right">% of Total</div>
            </div>
            {donutChartData.map(({ id: status, value, percentOfTotal, color }) => {
              const formattedNumberOfLines = addCommasToNumber(value);
              const formattedPercentOfTotal = formatToPercent(percentOfTotal, 1);

              return (
                <div
                  key={status}
                  className={`donut__table__row donut__table__row--body ${status === activeItem ? "donut__table__row--active" : ""}`}
                  onMouseEnter={() => setActiveItem(status)}
                  onMouseLeave={() => setActiveItem(null)}
                >
                  <div className="donut__table__body__item--id">
                    <LoadingSkeletonWrapper isLoading={isLoading}>
                      <div style={{ backgroundColor: color }} />
                      <span>{status}</span>
                    </LoadingSkeletonWrapper>
                  </div>
                  <div className="donut__table__row__item--align-right">
                    <LoadingSkeletonWrapper isLoading={isLoading}>
                      {formattedNumberOfLines}
                    </LoadingSkeletonWrapper>
                  </div>
                  <div className="donut__table__row__item--align-right">
                    <LoadingSkeletonWrapper isLoading={isLoading}>
                      {formattedPercentOfTotal}
                    </LoadingSkeletonWrapper>
                  </div>
                </div>
              );
            })}
            <div className="donut__table__row donut__table__row--footer">
              <div>Total</div>
              <div className="donut__table__row__item--align-right">
                <LoadingSkeletonWrapper isLoading={isLoading}>
                  {addCommasToNumber(data?.totalLines)}
                </LoadingSkeletonWrapper>
              </div>
              <div className="donut__table__row__item--align-right">
                <LoadingSkeletonWrapper isLoading={isLoading}>
                  {formatToPercent(totalPercent, 1)}
                </LoadingSkeletonWrapper>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ScrubResults.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default ScrubResults;
