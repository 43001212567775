import React, { createContext } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import { ArrowExpandDown, Check } from "./icons";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const { primary, secondaryText, primaryText } = colors;

const sizes = {
  default: "100%",
  md: 188,
  lg: 300,
};

const SelectContext = createContext();

const customSelectStyles = (size) => ({
  container: (base) => ({
    ...base,
    width: sizes[size],
  }),
});

const Control = ({ ...props }) => {
  const { testId } = React.useContext(SelectContext);

  return (
    <components.Control {...props} innerProps={{ ...props.innerProps, "data-testid": testId }} />
  );
};

const Option = ({ children, ...props }) => {
  const { emptyOptionLabel, value } = React.useContext(SelectContext);
  const isSelectedOption = value?.label === children && value.label !== emptyOptionLabel;

  const getOptionStyles = (base) => {
    let styles;

    if (isSelectedOption) {
      styles = {
        ...base,
        color: primary,
        fontWeight: 500,
      };
    } else {
      styles = {
        ...base,
        color: children === emptyOptionLabel || !children ? secondaryText : primaryText,
      };
    }

    return styles;
  };

  return (
    <components.Option
      {...props}
      innerProps={{ ...props.innerProps, "data-testid": "dropdown-option" }}
    >
      <div className="react-select__option__container">
        <span className="react-select__option--text" style={getOptionStyles()}>
          {children}
        </span>
        {isSelectedOption && <Check size={16} color={primary} />}
      </div>
    </components.Option>
  );
};

const SingleValue = ({ children = "", ...props }) => {
  const { emptyOptionLabel } = React.useContext(SelectContext);
  const isOptionEmptyOrLabel = children === emptyOptionLabel || !children;

  return (
    <components.SingleValue {...props}>
      <span
        style={{
          color: isOptionEmptyOrLabel ? secondaryText : primaryText,
          fontWeight: isOptionEmptyOrLabel ? 400 : 500,
        }}
        title={children}
      >
        {children || emptyOptionLabel}
      </span>
    </components.SingleValue>
  );
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <ArrowExpandDown size={20} color={secondaryText} />
  </components.DropdownIndicator>
);

const SelectSearch = ({
  inputId = "",
  testId = "",
  name = "",
  options,
  placeholder,
  emptyOptionLabel,
  size,
  defaultValue,
  value,
  disabled = false,
  onChange,
  disableDefaultSort = false,
}) => {
  const sortedOptions = disableDefaultSort
    ? options
    : options.sort((a, b) => a.label.localeCompare(b.label));
  const selectOptions = emptyOptionLabel
    ? [{ label: emptyOptionLabel, value: "" }, ...sortedOptions]
    : sortedOptions;

  return (
    <SelectContext.Provider value={{ value, emptyOptionLabel, testId }}>
      <Select
        inputId={inputId}
        className="react-select-container"
        classNamePrefix="react-select"
        components={{
          Control,
          Option,
          IndicatorSeparator: null,
          DropdownIndicator,
          SingleValue,
        }}
        options={selectOptions}
        name={name}
        placeholder={placeholder}
        styles={customSelectStyles(size)}
        menuPosition="fixed"
        menuPlacement="auto"
        noOptionsMessage={() => "No results"}
        openMenuOnClick={true}
        defaultValue={defaultValue}
        value={value}
        isDisabled={disabled}
        onChange={onChange}
      />
    </SelectContext.Provider>
  );
};

Control.propTypes = {
  innerProps: PropTypes.object.isRequired,
};

Option.propTypes = {
  innerProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

SingleValue.propTypes = {
  children: PropTypes.node,
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.object.isRequired,
};

SelectSearch.propTypes = {
  inputId: PropTypes.string,
  testId: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  emptyOptionLabel: PropTypes.string,
  size: PropTypes.oneOf(["lg", "md", "default"]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  disableDefaultSort: PropTypes.bool,
};

export default SelectSearch;
