import React from "react";
import PropTypes from "prop-types";

const CustomLegend = ({ className = "", legendProps }) => {
  return (
    <div className={`custom-legend ${className}`}>
      {legendProps.map(({ label, color }) => (
        <div key={label} className="custom-legend__item">
          <div className="custom-legend__symbol" style={{ backgroundColor: color }} />
          <span>{label}</span>
        </div>
      ))}
    </div>
  );
};

CustomLegend.propTypes = {
  className: PropTypes.string,
  legendProps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CustomLegend;
