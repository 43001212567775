import React from "react";
import PropTypes from "prop-types";

const Indicator = ({ isVisible = true, testId = null }) => {
  return (
    <div className="indicator__container">
      {isVisible && <div className="indicator" data-testid={testId} />}
    </div>
  );
};

Indicator.propTypes = {
  isVisible: PropTypes.bool,
  testId: PropTypes.string,
};

export default Indicator;
