import PropTypes from "prop-types";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

const defaultProps = {
  size: 16,
  color: colors.secondary,
};

export { propTypes, defaultProps };
