import React from "react";
import PropTypes from "prop-types";

const Tabs = ({ items, selectedItem, setSelectedItem }) => (
  <div className="tabs">
    {items.map((item) => (
      <button
        className={`tabs__item ${selectedItem === item ? "tabs__item--active" : ""}`}
        key={item}
        onClick={() => setSelectedItem(item)}
      >
        {item}
      </button>
    ))}
  </div>
);

Tabs.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.string.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
};

export default Tabs;
