import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import HorizontalBar from "../common/charts/HorizontalBar";
import { createQueryString } from "../utils/urlUtils";
import { extractValues } from "../utils/arrayUtils";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const DEBOUNCE_TIME = 500;

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const MatchRatesByProduct = ({ filters }) => {
  const [notice, setNotice] = useState(initialNoticeState);
  const { get: getClaimCountAndMatchRateData, isLoading, data, error } = useFetch();

  const debouncedFetchClaimCountAndMatchRateData = useCallback(
    debounce(getClaimCountAndMatchRateData, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (error) {
      setNotice({
        kind: "error",
        open: true,
        message: "Error retrieving match rates by product data",
      });
    }
  }, [error]);

  useEffect(() => {
    const { coveredEntities, products } = filters;
    const queryString = createQueryString({
      group_by: "product_name",
      id_340b: extractValues(coveredEntities),
      product_name: extractValues(products),
    });

    debouncedFetchClaimCountAndMatchRateData(`/api/reports/match_rate_report${queryString}`);
  }, [filters]);

  return (
    <>
      <div className="card card--width-40pct" data-testid="match-rates-by-product">
        <div className="card__header">
          <span className="card__title">Match Rates by Product</span>
          <span className="card__timestamp">All Time</span>
        </div>
        <HorizontalBar
          data={data?.map((obj) => ({
            matchRate: obj.matchRate,
            product: obj.groupByValue,
          }))}
          valueType="percent"
          isLoading={isLoading}
          indexBy="product"
          keys={["matchRate"]}
          colors={colors.purpleBlue}
          height={278}
          noDataMessage="There is no data that matches your filter selection"
        />
      </div>
      <Notice details={notice} />
    </>
  );
};

MatchRatesByProduct.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default MatchRatesByProduct;
