import React from "react";
import PropTypes from "prop-types";
import noResultsImgUrl from "../../frontend/images/application/no_results.svg";

const NoResults = ({
  description,
  width = "100%",
  height = "100%",
  imgWidth,
  backgroundColor = "transparent",
}) => (
  <div className="no-results" style={{ width, height, backgroundColor }}>
    <img
      src={noResultsImgUrl}
      width={imgWidth}
      alt="No results found"
      className="no-results__img"
    />
    <div className="no-results__header">No results found</div>
    <div className="no-results__description">{description}</div>
  </div>
);

NoResults.propTypes = {
  description: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgWidth: PropTypes.number,
  backgroundColor: PropTypes.string,
};

export default NoResults;
